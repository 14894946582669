
export default {
  name: "Table",
  props: {
    renderOption: {type: Array, required: true},
    props: {type: Object},
    data: {type: Array},
    loading: {type: Boolean, default: false},
    attribute: {type: Function, default: ()=> {}}
  },
  render() {
    return (
      <el-table
        v-loading={ this.loading }
        {...(this.props)}
        on={{...this.$listeners}} // 继承事件
        ref={'chTable'} // attrs 继承属性
        // jsx特殊性质 有些属性通过props传递
        props={{data: this.data, ...(this.props), ...this.$attrs }}>
        { this.renderOption && this.handleRenderList(this.renderOption) }
      </el-table>
    )
  },

  methods: {
    // 判断type并渲染对应
    handleRenderList(renderList) {
      let itemElement = []
      const typeToRender = (column, item) => {
        switch (column) {
          case 'text': itemElement.push(this.renderText(item))
            break
          case 'type': itemElement.push(this.renderType(item))
            break
          case 'switch': itemElement.push(this.renderSwitch(item)) // 不维护
            break
          case 'cash': itemElement.push(this.renderCash(item)) // 有精度的数字
            break
          case 'slot': itemElement.push(this.renderSlot(item))
            break
          case 'button': itemElement.push(this.renderButton(item)) // 不维护
            break
        }
      }
      renderList.forEach(({column, ...item}) => {
        typeToRender(column, item)
      })
      return itemElement
    },
    // 渲染文本
    renderText({label, prop, no = '—', yes, v = 0,  ...Attribute}) {
      const scopedSlots = {
        //defaul 默认具名插槽
        default: props => {
          return (<div {...this.attribute(props.row)}>
            {
              yes
                ? (props.row[prop] == v ? <span>{ no }</span> : <span style={{ color: 'red' }}>{ yes }</span>)
                :(props.row[prop] === '' || props.row[prop] === null || props.row[prop] === undefined )
                ? <span>{ no }</span>
                : <span>{ props.row[prop] }</span>
            }
            </div>)
        }
      }
      return (
        <el-table-column
          prop={prop}
          label={label}
          {...Attribute}
          props={{...Attribute}}
          {...{ scopedSlots }}
        />
      )
    },
    // 渲染Type
    renderType({label, prop,...Attribute}) {
      return (
        <el-table-column
          prop={prop}
          label={label}
          {...Attribute}
          props={{...Attribute}}
        />
      )
    },

    // 渲染金额
    renderCash({label, prop, precision = 2, ...Attribute}) {
      const scopedSlots = {
        //defaul 默认具名插槽
        default: props => {
          return (<div {...this.attribute(props.row)}>
            {
              (props.row[prop] === '' || props.row[prop] === null || props.row[prop] === undefined )
                ? <span>0.00</span>
                : <span>{Number(props.row[prop]).toFixed(precision)}</span>
            }
          </div>)
        }
      }
      return (
        <el-table-column
          prop={ prop }
          label={ label }
          {...Attribute}
          props={{...Attribute}}
          {...{ scopedSlots }}
        />
      )
    },

    // 渲染button
    renderButton({buttons, label, ...Attribute}) {
      const scopedSlots = {
        //defaul 默认具名插槽
        default: props => {
          return (
            // props 就相当于 slot-scope="{title}" 里面的值
            buttons.map(({show, onClick, text, ...Attribute}) => {
              return (
                <el-button size="mini" type="text"
                           {...Attribute}
                           props={{...Attribute}}
                           v-show={ show }
                           onClick={ () => onClick(props) }
                >{text}</el-button>
              )
            })
          )
        }
      }
      return (
        <el-table-column
          label={ label }
          props={{...Attribute }}
          {...{ scopedSlots }}
        />
      )
    },
    // 渲染Switch
    renderSwitch({label, show=true, prop, switchAttr, onChange, ...Attr}) {
      const scopedSlots = {
        default: props => {
          return (<el-switch
                  v-model={props.row[prop]}
                  props={{...switchAttr}}
                  {...switchAttr}
                  v-show={ show }
                  onChange={() => onChange(props)}
                />)
        }
      }
      return (<el-table-column
          label={ label } props={{...Attr }}
          {...{ scopedSlots }}
        />)
    },
    // 渲染插槽
    renderSlot({label, slotName, ...Attr}) {
      let that = this
      const scopedSlots = {
        default: (props) => {
          return that.$scopedSlots[slotName]({...props})
        }
      }
      return (
        <el-table-column
          label={label}
          props={{...Attr}}
          {...{ scopedSlots }}
        />
      )
    },

    getTableRef() {
      return this.$refs.chTable
    }
  }
}